import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "tervetuloa-tämä-on-organisaation-smilee-saavutettavuusseloste"
    }}>{`Tervetuloa, tämä on organisaation Smilee saavutettavuusseloste`}</h1>
    <p>{`Tämä saavutettavuusseloste koskee palvelua Smilee chat ja on päivitetty 30.09.2024. Palvelua koskee laki digitaalisten palvelujen tarjoamisesta, jossa edellytetään, että julkisten verkkopalvelujen on oltava saavutettavia. `}<br />{`
Olemme arvioineet palvelun saavutettavuuden itse`}</p>
    <h2 {...{
      "id": "digipalvelun-saavutettavuuden-tila"
    }}>{`Digipalvelun saavutettavuuden tila`}</h2>
    <p>{`Täyttää kaikki saavutettavuusvaatimukset tasolla AA`}</p>
    <h2 {...{
      "id": "huomasitko-saavutettavuuspuutteen-digipalvelussamme"
    }}>{`Huomasitko saavutettavuuspuutteen digipalvelussamme?`}</h2>
    <p>{`Kerro se meille ja teemme parhaamme puutteen korjaamiseksi sähköpostilla`}</p>
    <p>{`support@smilee.io`}</p>
    <h2 {...{
      "id": "valvontaviranomainen"
    }}>{`Valvontaviranomainen`}</h2>
    <p>{`Jos huomaat sivustolla saavutettavuusongelmia, anna ensin palautetta meille eli sivuston ylläpitäjälle. Vastauksessa voi mennä 14 päivää. Jos et ole tyytyväinen saamaasi vastaukseen tai et saa vastausta lainkaan kahden viikon aikana, `}<a parentName="p" {...{
        "href": "https://www.saavutettavuusvaatimukset.fi/oikeutesi/"
      }}>{`voit tehdä ilmoituksen Etelä-Suomen aluehallintovirastoon`}</a>{`. Etelä-Suomen aluehallintoviraston sivulla kerrotaan tarkasti, miten ilmoituksen voi tehdä ja miten asia käsitellään.`}</p>
    <h2 {...{
      "id": "valvontaviranomaisen-yhteystiedot"
    }}>{`Valvontaviranomaisen yhteystiedot`}</h2>
    <p>{`Etelä-Suomen aluehallintovirasto `}<br />{`
Saavutettavuuden valvonnan yksikkö `}<br />{`
`}<a parentName="p" {...{
        "href": "www.saavutettavuusvaatimukset.fi"
      }}>{`www.saavutettavuusvaatimukset.fi`}</a>{` `}<br />{`
saavutettavuus@avi.fi `}<br />{`
puhelinnumero vaihde 0295 016 000`}</p>
    <h2 {...{
      "id": "teemme-jatkuvasti-työtä-saavutettavuuden-parantamiseksi"
    }}>{`Teemme jatkuvasti työtä saavutettavuuden parantamiseksi`}</h2>
    <h3 {...{
      "id": "olemme-sitoutuneet-digipalveluiden-saavutettavuuden-parantamiseen"
    }}>{`Olemme sitoutuneet digipalveluiden saavutettavuuden parantamiseen`}</h3>
    <p>{`Smilee on sitoutunut tekemään chat-palvelusta mahdollisimman saavutettavan kaikille asiakkailleen ja on sitoutunut korjaamaan kaikki saavutettavuuteen liittyvät löydökset palvelusta.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      